import React from "react"
import { Helmet } from "react-helmet"

export function Head({ data }) {
  return (
    <Helmet
      title="Dr. Ernstfried Hanisch – Praxis für Psychotherapie"
      meta={[
        {
          name: "description",
          content: "Als Psychologischer Psychotherapeut habe ich mehr als 45 Jahre Berufserfahrung und praktiziere in München mit Kassenzulassung. Ich bin staatlich anerkannter Ausbilder für Psychotherapeuten, Seminarleiter, Lehrtherapeut und Supervisor.",
        }
      ]}
    >
      <html lang="en-US" amp />
    </Helmet>
  )
}
