import React from "react"
import { Container } from "react-bootstrap"
import { Link } from "./link"

export function Footer(props) {
  return (
    <Container fluid className="bg-light px-0 py-3 text-center">
      <Link href="/imprint"><span className="text-dark">Impressum</span></Link>
    </Container>
  )
}
