import React from "react"
import { Link } from 'gatsby'
import { Navbar, Nav, Image } from 'react-bootstrap'
import Logo from "../../src/img/logo.png"
import { AnchorLink } from "gatsby-plugin-anchor-links"

class Header extends React.Component {
    componentDidMount() {
        const nav = document.getElementById('nav');
        const scroll = 100;

        window.onscroll = function () {
            if (window.pageYOffset >= scroll) {
                nav.classList.add('bg');
            } else {
                nav.classList.remove('bg');
            }
        }
    }

    render() {
        return (
            <Navbar id="nav" expand="lg" fixed="top">
                <Navbar.Brand>
                    <Link to="/">
                        <Image src={Logo} height="45" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="nav" className="toogle-btn" />
                <Navbar.Collapse id="nav">
                    <Nav className="ml-auto">
                        <Nav.Link className="text-white" href="#">
                            <AnchorLink className="nav-link text-white" to="/#about">
                                Über mich
                            </AnchorLink>
                        </Nav.Link>
                        <Nav.Link className="text-white" href="#">
                            <AnchorLink className="nav-link text-white" to="/#therapy">
                                Therapie
                        </AnchorLink>
                        </Nav.Link>
                        {/* <Nav.Link className="text-white" href="/book">Mein Buch</Nav.Link> */}
                        {/* <Nav.Link className="text-white" href="/faq">FAQ</Nav.Link> */}
                        <Nav.Link className="text-white" href="#">
                            <Link className="text-white nav-link" to="/book">Mein Buch</Link>
                        </Nav.Link>
                        <Nav.Link className="text-white" href="#">
                            <Link className="text-white nav-link" to="/faq">FAQ</Link>
                        </Nav.Link>
                        <Nav.Link className="text-white" href="#">
                            <AnchorLink className="nav-link text-white" to="/#contact">
                                Kontakt
                        </AnchorLink>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default Header